import { useState, useEffect } from 'react';
import '../../style/header.scss';
import { Logo } from '../../svg/Logo';
import LanguageSelect from './LanguageSelect';
import RouteList from './RouteList';
import { ButtonApp } from './ButtonApp';
import Burger from './Burger';
import { LanguageType } from '../../types/types';

const Header = ({ setLanguage }: { setLanguage: (s: LanguageType) => void }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > lastScrollY && currentScrollY > 100) { // Условие для скрытия шапки при прокрутке вниз
            setIsVisible(false);
        } else if (currentScrollY < lastScrollY) { // Условие для появления шапки при прокрутке вверх
            setIsVisible(true);
        }
        setLastScrollY(currentScrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    return (
        <nav className='header' style={{ top: isVisible ? '0' : '-100px', transition: 'top 0.3s' }}>
            <div className='container'>
                <div className='header__body'>
                    <div className='header__logo'>
                        <Logo />
                    </div>
                    <div className='header__nav'>
                        <RouteList />
                    </div>
                    <div className='header__button'>
                        <ButtonApp />
                    </div>
                    <LanguageSelect setLanguage={setLanguage}/>
                    <Burger />
                </div>
            </div>
        </nav>
    );
};

export default Header;
