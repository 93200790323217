import React from 'react'

export const Close = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M6.87744 11.1224L11.1224 6.87744" stroke="#CFB6FB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.1224 11.1224L6.87744 6.87744" stroke="#CFB6FB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" stroke="#CFB6FB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
