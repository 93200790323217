export const Logo = () => {
    return (
        <svg width="211" height="30" viewBox="0 0 211 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7 0H13.5714H19.2858H20.1428C28.3482 0 35 6.49187 35 14.5C35 22.5081 28.3482 29 20.1428 29H13.5714V22.3079V22.3077H20.1428C20.1542 22.3077 20.1657 22.3077 20.1771 22.3078C24.5796 22.2899 28.1429 18.8011 28.1429 14.5003C28.1429 10.1882 24.5611 6.69254 20.1429 6.69254H13.5714V6.69231H7V0Z" fill="url(#paint0_linear_609_8968)" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.6341 11H0V17.5998H19.6341C21.4931 17.5998 23 16.1224 23 14.2999C23 12.4774 21.4931 11 19.6341 11ZM6.7317 17.6001H0V22H6.7317V17.6001Z" fill="url(#paint1_linear_609_8968)" />
            <path d="M62.4118 14.4271C62.8997 14.4271 63.2931 14.2107 63.5922 13.7779C63.899 13.3374 64.0524 12.8742 64.0524 12.3881V10.9186C64.0524 10.4326 63.899 9.97314 63.5922 9.54031C63.2931 9.0998 62.8997 8.87959 62.4118 8.87959H49.9476V21H48V7H62.4118C63.3954 7 64.2374 7.38351 64.9377 8.15056C65.6459 8.91754 66 9.84022 66 10.9186V12.3881C66 13.4665 65.6459 14.3892 64.9377 15.1563C64.2374 15.9232 63.3954 16.3067 62.4118 16.3067H53.7363V14.4271H62.4118Z" fill="#883FC3" />
            <path d="M95.4171 7C96.4028 7 97.2458 7.38351 97.9478 8.15056C98.649 8.91754 99 9.84022 99 10.9186V17.0813C99 18.1598 98.649 19.0824 97.9478 19.8494C97.2458 20.6165 96.4028 21 95.4171 21H83.5824C82.5971 21 81.7537 20.6165 81.0523 19.8494C80.3508 19.0824 80 18.1598 80 17.0813V10.9186C80 9.84022 80.3508 8.91754 81.0523 8.15056C81.7537 7.38351 82.5971 7 83.5824 7H95.4171ZM95.4171 19.1204C95.5589 19.1204 95.6815 19.1052 95.7839 19.0749C96.1699 18.9609 96.4772 18.7065 96.7065 18.3116C96.935 17.9091 97.0488 17.499 97.0488 17.0813V10.9186C97.0488 10.5009 96.935 10.0947 96.7065 9.69971C96.4772 9.29724 96.1699 9.03908 95.7839 8.92513C95.6815 8.89477 95.5589 8.87959 95.4171 8.87959H83.5824C83.4406 8.87959 83.3184 8.89477 83.2159 8.92513C82.8297 9.03908 82.5223 9.29724 82.2937 9.69971C82.0651 10.0947 81.9508 10.5009 81.9508 10.9186V17.0813C81.9508 17.499 82.0651 17.9091 82.2937 18.3116C82.5223 18.7065 82.8297 18.9609 83.2159 19.0749C83.3184 19.1052 83.4406 19.1204 83.5824 19.1204H95.4171Z" fill="#883FC3" />
            <path d="M127.412 14.4271C127.9 14.4271 128.293 14.2107 128.592 13.7779C128.899 13.3374 129.052 12.8742 129.052 12.3881V10.9186C129.052 10.4326 128.899 9.97314 128.592 9.54031C128.293 9.0998 127.9 8.87959 127.412 8.87959H114.948V21H113V7H127.412C128.395 7 129.237 7.38351 129.937 8.15056C130.646 8.91754 131 9.84022 131 10.9186V12.3881C131 13.4665 130.646 14.3892 129.937 15.1563C129.237 15.9232 128.395 16.3067 127.412 16.3067H118.737V14.4271H127.412Z" fill="#883FC3" />
            <path d="M145 17.0928V7H146.951V17.0928C146.951 17.5104 147.066 17.9167 147.294 18.3116C147.523 18.7065 147.83 18.9609 148.216 19.0749C148.35 19.1128 148.473 19.1318 148.583 19.1318H160.418C160.528 19.1318 160.651 19.1128 160.785 19.0749C161.171 18.9609 161.478 18.7065 161.707 18.3116C161.935 17.9167 162.05 17.5104 162.05 17.0928V7H164V17.0928C164 18.1712 163.65 19.0938 162.948 19.8608C162.247 20.6203 161.403 21 160.418 21H148.583C147.597 21 146.754 20.6203 146.053 19.8608C145.351 19.0938 145 18.1712 145 17.0928Z" fill="#883FC3" />
            <path d="M195 19.1204V21H181.56C180.995 21 180.46 20.8557 179.957 20.5671C179.8 20.476 179.686 20.4038 179.615 20.3507C179.112 19.9861 178.715 19.5153 178.424 18.9381C178.141 18.361 178 17.742 178 17.0813V7H179.946V17.0813C179.946 17.4231 180.016 17.7572 180.158 18.0838C180.307 18.4103 180.515 18.6685 180.783 18.8584C180.798 18.8736 180.822 18.8926 180.853 18.9154C180.893 18.9306 180.92 18.9419 180.935 18.9495C181.148 19.0635 181.356 19.1204 181.56 19.1204H195Z" fill="#883FC3" />
            <path d="M208 7H210V21H208V7Z" fill="#883FC3" />
            <defs>
                <linearGradient id="paint0_linear_609_8968" x1="-1.62401" y1="-8.83929" x2="40.5212" y2="21.2724" gradientUnits="userSpaceOnUse">
                    <stop offset="0.302788" stopColor="#516BEB" />
                    <stop offset="0.578125" stopColor="#9234C1" />
                    <stop offset="0.833947" stopColor="#BC2ABB" />
                    <stop offset="1" stopColor="#D11CBC" />
                </linearGradient>
                <linearGradient id="paint1_linear_609_8968" x1="-7.08399" y1="7.64714" x2="8.32312" y2="31.4858" gradientUnits="userSpaceOnUse">
                    <stop offset="0.302788" stopColor="#516BEB" />
                    <stop offset="0.578125" stopColor="#9234C1" />
                    <stop offset="0.833947" stopColor="#BC2ABB" />
                    <stop offset="1" stopColor="#D11CBC" />
                </linearGradient>
            </defs>
        </svg>

    )
}
