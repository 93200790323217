import { LANGUAGE } from "../types/enum"

export const LANGUAGES = [LANGUAGE.EN, LANGUAGE.RU, LANGUAGE.UA]

export const DOMAIN = 'https://back.populi.space/api'

//export const DOMAIN = 'https://contacts.design/api'
//'http://localhost:5000'
//'https://contacts.design'

export const URL_APP = [
    '/',
    'about',
    'contacts',
    'policy',
    'agreement'
]


export const INDEX_APP = ['APP__CONTENT', 'MainCategories', 'MainFindsFriend', 'MainDifferent', 'FOOTER'];