import React from 'react'

export const AppStore = () => {
    return (
        <svg width="165" height="47" viewBox="0 0 165 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M163.808 41.5274C163.808 43.7969 161.927 45.6538 159.576 45.6538H4.73763C2.38733 45.6538 0.5 43.796 0.5 41.5274V4.63254C0.5 2.3642 2.38828 0.5 4.73763 0.5H159.575C161.926 0.5 163.806 2.36319 163.806 4.63254L163.808 41.5274Z" stroke="url(#paint0_linear_569_9096)" />
            <path d="M36.1575 22.8179C36.1218 18.9532 39.4055 17.0731 39.5557 16.9855C37.696 14.3439 34.8135 13.983 33.8006 13.9542C31.3797 13.706 29.0314 15.3656 27.7982 15.3656C26.5403 15.3656 24.6412 13.9782 22.5945 14.019C19.9606 14.0585 17.4966 15.5442 16.1452 17.8513C13.3563 22.5553 15.4363 29.4681 18.1083 33.2704C19.4449 35.1326 21.0068 37.2119 23.0511 37.1387C25.0511 37.0584 25.7982 35.8965 28.2117 35.8965C30.6031 35.8965 31.3046 37.1387 33.3895 37.092C35.536 37.0584 36.8874 35.2214 38.1772 33.3424C39.7218 31.208 40.3422 29.106 40.3668 28.9981C40.3163 28.9813 36.1982 27.45 36.1575 22.8179Z" fill="url(#paint1_linear_569_9096)" />
            <path d="M32.2191 11.4529C33.2948 10.1423 34.0308 8.35924 33.8265 6.5498C32.2695 6.61695 30.3225 7.59901 29.2012 8.88085C28.2092 10.0104 27.3231 11.8618 27.552 13.6029C29.3009 13.73 31.0966 12.7431 32.2191 11.4529Z" fill="url(#paint2_linear_569_9096)" />
            <path d="M65.1016 36.8711H62.3065L60.7755 32.1839H55.4536L53.9952 36.8711H51.2739L56.5465 20.9136H59.8032L65.1016 36.8711ZM60.3139 30.2174L58.9293 26.0505C58.7828 25.6248 58.5084 24.6224 58.1035 23.0444H58.0542C57.893 23.7231 57.6333 24.7255 57.2764 26.0505L55.9164 30.2174H60.3139Z" fill="url(#paint3_linear_569_9096)" />
            <path d="M78.661 30.9764C78.661 32.9333 78.1182 34.4802 77.0327 35.6157C76.0604 36.6265 74.853 37.1314 73.4118 37.1314C71.8561 37.1314 70.7385 36.587 70.0579 35.4982H70.0087V41.5596H67.3847V29.1526C67.3847 27.9223 67.3515 26.6597 67.2875 25.3646H69.5951L69.7416 27.1885H69.7908C70.6659 25.8143 71.9939 25.1284 73.7761 25.1284C75.1693 25.1284 76.3324 25.6644 77.2628 26.7376C78.1958 27.812 78.661 29.2245 78.661 30.9764ZM75.9878 31.0699C75.9878 29.95 75.7293 29.0267 75.2099 28.3C74.6425 27.5422 73.8807 27.1633 72.9256 27.1633C72.2782 27.1633 71.6899 27.3743 71.1644 27.7904C70.6376 28.2101 70.293 28.7581 70.1318 29.4368C70.0505 29.7533 70.0099 30.0123 70.0099 30.2162V32.1347C70.0099 32.9717 70.2733 33.678 70.8001 34.2547C71.3268 34.8315 72.0111 35.1193 72.853 35.1193C73.8413 35.1193 74.6105 34.7476 75.1607 34.0065C75.7121 33.2643 75.9878 32.2858 75.9878 31.0699Z" fill="url(#paint4_linear_569_9096)" />
            <path d="M92.245 30.9764C92.245 32.9333 91.7022 34.4802 90.6155 35.6157C89.6444 36.6265 88.437 37.1314 86.9958 37.1314C85.4401 37.1314 84.3225 36.587 83.6432 35.4982H83.5939V41.5596H80.9699V29.1526C80.9699 27.9223 80.9367 26.6597 80.8727 25.3646H83.1804L83.3269 27.1885H83.3761C84.2499 25.8143 85.5779 25.1284 87.3613 25.1284C88.7533 25.1284 89.9164 25.6644 90.8493 26.7376C91.7785 27.812 92.245 29.2245 92.245 30.9764ZM89.5718 31.0699C89.5718 29.95 89.3121 29.0267 88.7927 28.3C88.2253 27.5422 87.4659 27.1633 86.5096 27.1633C85.861 27.1633 85.2739 27.3743 84.7472 27.7904C84.2204 28.2101 83.877 28.7581 83.7158 29.4368C83.6358 29.7533 83.5939 30.0123 83.5939 30.2162V32.1347C83.5939 32.9717 83.8573 33.678 84.3816 34.2547C84.9084 34.8303 85.5927 35.1193 86.437 35.1193C87.4253 35.1193 88.1945 34.7476 88.7447 34.0065C89.2961 33.2643 89.5718 32.2858 89.5718 31.0699Z" fill="url(#paint5_linear_569_9096)" />
            <path d="M107.433 32.396C107.433 33.7534 106.949 34.8578 105.978 35.7103C104.911 36.642 103.425 37.1073 101.516 37.1073C99.7539 37.1073 98.341 36.7763 97.2715 36.1132L97.8795 33.9824C99.0315 34.6611 100.295 35.0017 101.673 35.0017C102.661 35.0017 103.43 34.7834 103.983 34.3494C104.533 33.9153 104.807 33.3325 104.807 32.6059C104.807 31.9584 104.581 31.4128 104.127 30.9703C103.675 30.5279 102.921 30.1166 101.867 29.7365C98.9995 28.6944 97.5668 27.168 97.5668 25.1607C97.5668 23.8489 98.069 22.7733 99.0745 21.9363C100.076 21.0982 101.413 20.6797 103.084 20.6797C104.575 20.6797 105.813 20.9327 106.801 21.4375L106.145 23.5215C105.222 23.0323 104.179 22.7877 103.011 22.7877C102.087 22.7877 101.366 23.0095 100.849 23.4508C100.412 23.8453 100.193 24.3261 100.193 24.8957C100.193 25.5264 100.443 26.048 100.945 26.4581C101.382 26.837 102.176 27.2471 103.328 27.6896C104.737 28.2424 105.772 28.8887 106.438 29.6297C107.102 30.3684 107.433 31.2929 107.433 32.396Z" fill="url(#paint6_linear_569_9096)" />
            <path d="M116.108 27.2831H113.216V32.8697C113.216 34.2907 113.726 35.0005 114.747 35.0005C115.216 35.0005 115.605 34.961 115.913 34.8818L115.985 36.8231C115.468 37.0114 114.788 37.1061 113.945 37.1061C112.908 37.1061 112.099 36.798 111.514 36.1828C110.932 35.5665 110.639 34.5329 110.639 33.0808V27.2807H108.916V25.3622H110.639V23.2554L113.216 22.4976V25.3622H116.108V27.2831Z" fill="url(#paint7_linear_569_9096)" />
            <path d="M129.158 31.0229C129.158 32.7916 128.639 34.2437 127.603 35.3793C126.516 36.5484 125.073 37.1311 123.275 37.1311C121.542 37.1311 120.163 36.5712 119.134 35.4512C118.105 34.3312 117.59 32.9175 117.59 31.2136C117.59 29.4305 118.119 27.9701 119.182 26.8345C120.241 25.6978 121.671 25.1294 123.47 25.1294C125.203 25.1294 126.597 25.6894 127.649 26.8105C128.656 27.8981 129.158 29.3022 129.158 31.0229ZM126.436 31.1057C126.436 30.0445 126.203 29.1344 125.732 28.3753C125.182 27.4568 124.395 26.9988 123.376 26.9988C122.321 26.9988 121.52 27.458 120.97 28.3753C120.499 29.1356 120.266 30.0601 120.266 31.1536C120.266 32.2148 120.499 33.125 120.97 33.8828C121.537 34.8013 122.33 35.2593 123.353 35.2593C124.355 35.2593 125.141 34.7917 125.708 33.8588C126.192 33.0854 126.436 32.1657 126.436 31.1057Z" fill="url(#paint8_linear_569_9096)" />
            <path d="M137.687 27.6129C137.428 27.5662 137.151 27.5422 136.86 27.5422C135.937 27.5422 135.223 27.8815 134.721 28.5614C134.284 29.161 134.065 29.9188 134.065 30.8337V36.8712H131.443L131.467 28.9883C131.467 27.6621 131.434 26.4546 131.369 25.3658H133.654L133.75 27.5674H133.823C134.1 26.8107 134.537 26.2016 135.135 25.7448C135.719 25.3335 136.351 25.1284 137.031 25.1284C137.274 25.1284 137.493 25.1452 137.687 25.1752V27.6129Z" fill="url(#paint9_linear_569_9096)" />
            <path d="M149.423 30.5731C149.423 31.0312 149.392 31.4173 149.327 31.7326H141.455C141.486 32.8694 141.866 33.7387 142.597 34.3383C143.26 34.8743 144.118 35.1429 145.172 35.1429C146.337 35.1429 147.401 34.9618 148.357 34.5985L148.768 36.3731C147.651 36.848 146.331 37.0842 144.809 37.0842C142.977 37.0842 141.54 36.559 140.494 35.5098C139.45 34.4606 138.927 33.0516 138.927 31.2842C138.927 29.5491 139.413 28.1042 140.387 26.9519C141.406 25.7216 142.783 25.1064 144.516 25.1064C146.218 25.1064 147.507 25.7216 148.382 26.9519C149.075 27.9291 149.423 29.1378 149.423 30.5731ZM146.921 29.91C146.938 29.1522 146.767 28.4975 146.411 27.9447C145.957 27.2336 145.259 26.8787 144.32 26.8787C143.462 26.8787 142.764 27.2252 142.231 27.9207C141.795 28.4735 141.535 29.1366 141.455 29.9088H146.921V29.91Z" fill="url(#paint10_linear_569_9096)" />
            <path d="M59.4462 11.0969C59.4462 12.5083 59.0117 13.5707 58.144 14.2841C57.3403 14.9424 56.1982 15.2722 54.7188 15.2722C53.9852 15.2722 53.3575 15.241 52.832 15.1787V7.46728C53.5175 7.35936 54.256 7.3042 55.0535 7.3042C56.4628 7.3042 57.5249 7.60277 58.2412 8.19992C59.0437 8.87501 59.4462 9.84029 59.4462 11.0969ZM58.0862 11.1317C58.0862 10.2168 57.8375 9.51533 57.3403 9.0261C56.8431 8.53807 56.1169 8.29345 55.1606 8.29345C54.7545 8.29345 54.4086 8.31983 54.1218 8.37499V14.2374C54.2806 14.2614 54.5711 14.2721 54.9932 14.2721C55.9803 14.2721 56.7422 14.0047 57.2788 13.4699C57.8154 12.9352 58.0862 12.1557 58.0862 11.1317Z" fill="url(#paint11_linear_569_9096)" />
            <path d="M66.6573 12.3297C66.6573 13.199 66.4025 13.9113 65.8929 14.4701C65.3588 15.0444 64.6511 15.331 63.7674 15.331C62.9157 15.331 62.2376 15.0564 61.7317 14.5048C61.2271 13.9544 60.9748 13.2602 60.9748 12.4232C60.9748 11.5479 61.2345 10.8296 61.7563 10.272C62.2782 9.71445 62.9797 9.43506 63.8634 9.43506C64.7151 9.43506 65.3994 9.70965 65.9176 10.26C66.4099 10.7948 66.6573 11.4855 66.6573 12.3297ZM65.3194 12.3704C65.3194 11.8488 65.2037 11.4016 64.9736 11.0287C64.7028 10.5778 64.3176 10.3524 63.8166 10.3524C63.2985 10.3524 62.9046 10.5778 62.6339 11.0287C62.4025 11.4016 62.288 11.856 62.288 12.3932C62.288 12.9148 62.4037 13.3621 62.6339 13.735C62.9132 14.1859 63.3022 14.4113 63.8043 14.4113C64.2966 14.4113 64.6831 14.1823 64.9612 13.723C65.2 13.3429 65.3194 12.892 65.3194 12.3704Z" fill="url(#paint12_linear_569_9096)" />
            <path d="M76.3262 9.55029L74.5108 15.2028H73.3292L72.5772 12.7483C72.3865 12.1355 72.2314 11.5264 72.1108 10.9221H72.0874C71.9754 11.5432 71.8203 12.1511 71.6209 12.7483L70.8222 15.2028H69.6271L67.92 9.55029H69.2455L69.9015 12.2375C70.0603 12.873 70.1908 13.4785 70.2954 14.0517H70.3188C70.4148 13.5793 70.5735 12.9773 70.7975 12.2495L71.6209 9.55149H72.672L73.4609 12.1919C73.6517 12.8358 73.8068 13.4557 73.9262 14.0529H73.9618C74.0492 13.4713 74.1809 12.8514 74.3557 12.1919L75.0597 9.55149H76.3262V9.55029Z" fill="url(#paint13_linear_569_9096)" />
            <path d="M83.0129 15.2024H81.7231V11.9648C81.7231 10.9672 81.3342 10.4684 80.5538 10.4684C80.1711 10.4684 79.8622 10.6051 79.6222 10.8797C79.3846 11.1543 79.264 11.478 79.264 11.8485V15.2012H77.9742V11.165C77.9742 10.6686 77.9582 10.1302 77.9274 9.54746H79.0609L79.1212 10.4312H79.1569C79.3071 10.1566 79.5311 9.92998 79.8252 9.74891C80.1748 9.53787 80.5662 9.43115 80.9945 9.43115C81.536 9.43115 81.9865 9.60142 82.3446 9.94317C82.7902 10.3617 83.0129 10.9864 83.0129 11.8162V15.2024Z" fill="url(#paint14_linear_569_9096)" />
            <path d="M86.5699 15.2027H85.2812V6.95654H86.5699V15.2027Z" fill="url(#paint15_linear_569_9096)" />
            <path d="M94.1637 12.3297C94.1637 13.199 93.9089 13.9113 93.3994 14.4701C92.8652 15.0444 92.1563 15.331 91.2739 15.331C90.4209 15.331 89.7428 15.0564 89.2382 14.5048C88.7335 13.9544 88.4812 13.2602 88.4812 12.4232C88.4812 11.5479 88.7409 10.8296 89.2628 10.272C89.7846 9.71445 90.4862 9.43506 91.3686 9.43506C92.2215 9.43506 92.9046 9.70965 93.424 10.26C93.9163 10.7948 94.1637 11.4855 94.1637 12.3297ZM92.8246 12.3704C92.8246 11.8488 92.7089 11.4016 92.4788 11.0287C92.2092 10.5778 91.8228 10.3524 91.3231 10.3524C90.8037 10.3524 90.4098 10.5778 90.1403 11.0287C89.9089 11.4016 89.7945 11.856 89.7945 12.3932C89.7945 12.9148 89.9102 13.3621 90.1403 13.735C90.4197 14.1859 90.8086 14.4113 91.3108 14.4113C91.8031 14.4113 92.1883 14.1823 92.4665 13.723C92.7065 13.3429 92.8246 12.892 92.8246 12.3704Z" fill="url(#paint16_linear_569_9096)" />
            <path d="M100.406 15.2027H99.248L99.152 14.5516H99.1163C98.72 15.0708 98.1551 15.331 97.4215 15.331C96.8738 15.331 96.4308 15.1595 96.0972 14.819C95.7945 14.5096 95.6431 14.1247 95.6431 13.6679C95.6431 12.9772 95.9385 12.4508 96.5329 12.0863C97.1261 11.7217 97.9606 11.5431 99.0351 11.5515V11.4459C99.0351 10.7013 98.6338 10.3296 97.8301 10.3296C97.2578 10.3296 96.7532 10.4699 96.3175 10.7481L96.0554 9.92309C96.5944 9.59813 97.2603 9.43506 98.0455 9.43506C99.5618 9.43506 100.322 10.2145 100.322 11.7733V13.8549C100.322 14.4197 100.351 14.8694 100.406 15.2027ZM99.0671 13.2602V12.3884C97.6443 12.3644 96.9329 12.7446 96.9329 13.5276C96.9329 13.8225 97.0141 14.0432 97.1803 14.1907C97.3464 14.3382 97.5581 14.4113 97.8104 14.4113C98.0935 14.4113 98.3581 14.3238 98.5994 14.1499C98.8418 13.9748 98.9908 13.753 99.0461 13.4808C99.0597 13.4197 99.0671 13.3453 99.0671 13.2602Z" fill="url(#paint17_linear_569_9096)" />
            <path d="M107.735 15.2027H106.591L106.53 14.295H106.495C106.129 14.9857 105.506 15.331 104.631 15.331C103.932 15.331 103.35 15.0636 102.889 14.5288C102.427 13.994 102.197 13.2998 102.197 12.4472C102.197 11.5323 102.447 10.7912 102.949 10.2253C103.435 9.69767 104.031 9.43387 104.74 9.43387C105.519 9.43387 106.064 9.68928 106.374 10.2013H106.399V6.95654H107.69V13.6799C107.69 14.2303 107.705 14.7375 107.735 15.2027ZM106.399 12.8189V11.8764C106.399 11.7134 106.386 11.5815 106.363 11.4807C106.29 11.1786 106.134 10.9243 105.897 10.7193C105.657 10.5143 105.367 10.4111 105.034 10.4111C104.553 10.4111 104.176 10.597 103.899 10.9699C103.625 11.3428 103.486 11.8189 103.486 12.4004C103.486 12.9592 103.617 13.4125 103.882 13.7614C104.161 14.1331 104.538 14.319 105.009 14.319C105.433 14.319 105.771 14.1643 106.028 13.8537C106.277 13.5672 106.399 13.2218 106.399 12.8189Z" fill="url(#paint18_linear_569_9096)" />
            <path d="M118.767 12.3297C118.767 13.199 118.512 13.9113 118.002 14.4701C117.468 15.0444 116.762 15.331 115.877 15.331C115.026 15.331 114.348 15.0564 113.841 14.5048C113.337 13.9544 113.084 13.2602 113.084 12.4232C113.084 11.5479 113.344 10.8296 113.866 10.272C114.388 9.71445 115.089 9.43506 115.974 9.43506C116.825 9.43506 117.51 9.70965 118.027 10.26C118.519 10.7948 118.767 11.4855 118.767 12.3297ZM117.43 12.3704C117.43 11.8488 117.314 11.4016 117.084 11.0287C116.812 10.5778 116.428 10.3524 115.926 10.3524C115.409 10.3524 115.015 10.5778 114.743 11.0287C114.512 11.4016 114.398 11.856 114.398 12.3932C114.398 12.9148 114.513 13.3621 114.743 13.735C115.023 14.1859 115.412 14.4113 115.914 14.4113C116.406 14.4113 116.794 14.1823 117.072 13.723C117.31 13.3429 117.43 12.892 117.43 12.3704Z" fill="url(#paint19_linear_569_9096)" />
            <path d="M125.702 15.2024H124.414V11.9648C124.414 10.9672 124.025 10.4684 123.243 10.4684C122.86 10.4684 122.551 10.6051 122.313 10.8797C122.074 11.1543 121.954 11.478 121.954 11.8485V15.2012H120.663V11.165C120.663 10.6686 120.649 10.1302 120.618 9.54746H121.75L121.81 10.4312H121.846C121.998 10.1566 122.222 9.92998 122.514 9.74891C122.865 9.53787 123.255 9.43115 123.685 9.43115C124.225 9.43115 124.676 9.60142 125.034 9.94317C125.481 10.3617 125.702 10.9864 125.702 11.8162V15.2024Z" fill="url(#paint20_linear_569_9096)" />
            <path d="M134.383 10.4916H132.962V13.2375C132.962 13.9354 133.215 14.2843 133.714 14.2843C133.946 14.2843 134.138 14.2652 134.289 14.2256L134.322 15.1789C134.068 15.2724 133.733 15.3192 133.321 15.3192C132.811 15.3192 132.415 15.1681 132.128 14.8659C131.84 14.5637 131.697 14.0553 131.697 13.3419V10.4916H130.849V9.55032H131.697V8.5143L132.961 8.14258V9.54912H134.382V10.4916H134.383Z" fill="url(#paint21_linear_569_9096)" />
            <path d="M141.211 15.2027H139.92V11.9891C139.92 10.9759 139.531 10.4687 138.752 10.4687C138.154 10.4687 137.745 10.7625 137.521 11.35C137.483 11.4735 137.461 11.6246 137.461 11.8021V15.2015H136.172V6.95654H137.461V10.3632H137.486C137.892 9.74324 138.474 9.43387 139.228 9.43387C139.762 9.43387 140.204 9.60414 140.555 9.94589C140.992 10.3716 141.211 11.0047 141.211 11.8417V15.2027Z" fill="url(#paint22_linear_569_9096)" />
            <path d="M148.255 12.1091C148.255 12.3345 148.238 12.524 148.207 12.6787H144.338C144.356 13.2374 144.54 13.6631 144.898 13.9581C145.226 14.2219 145.648 14.3538 146.165 14.3538C146.737 14.3538 147.259 14.2651 147.729 14.0864L147.931 14.9593C147.381 15.192 146.734 15.3083 145.984 15.3083C145.086 15.3083 144.378 15.0505 143.866 14.5349C143.351 14.0192 143.097 13.3274 143.097 12.4592C143.097 11.6067 143.334 10.8968 143.813 10.3308C144.313 9.72649 144.988 9.42432 145.841 9.42432C146.676 9.42432 147.31 9.72649 147.738 10.3308C148.084 10.8105 148.255 11.404 148.255 12.1091ZM147.024 11.7841C147.034 11.4112 146.949 11.0899 146.774 10.8189C146.55 10.4699 146.209 10.2949 145.748 10.2949C145.327 10.2949 144.983 10.4651 144.721 10.8069C144.507 11.0791 144.38 11.404 144.338 11.7841H147.024Z" fill="url(#paint23_linear_569_9096)" />
            <defs>
                <linearGradient id="paint0_linear_569_9096" x1="0" y1="0" x2="35.8625" y2="94.7631" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint1_linear_569_9096" x1="14.8208" y1="6.5498" x2="46.9746" y2="26.4801" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint2_linear_569_9096" x1="14.8208" y1="6.5498" x2="46.9746" y2="26.4801" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint3_linear_569_9096" x1="51.2739" y1="20.9136" x2="68.3155" y2="31.8743" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint4_linear_569_9096" x1="67.2875" y1="25.1284" x2="82.9647" y2="33.183" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint5_linear_569_9096" x1="80.8727" y1="25.1284" x2="96.5489" y2="33.1817" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint6_linear_569_9096" x1="97.2715" y1="20.6797" x2="111.893" y2="27.3926" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint7_linear_569_9096" x1="108.916" y1="22.4976" x2="119.971" y2="26.5376" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint8_linear_569_9096" x1="117.59" y1="25.1294" x2="130.921" y2="34.6668" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint9_linear_569_9096" x1="131.369" y1="25.1284" x2="140.863" y2="28.9205" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint10_linear_569_9096" x1="138.927" y1="25.1064" x2="151.777" y2="33.4646" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint11_linear_569_9096" x1="52.832" y1="7.3042" x2="61.1847" y2="12.4506" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint12_linear_569_9096" x1="60.9748" y1="9.43506" x2="67.5236" y2="14.1199" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint13_linear_569_9096" x1="67.92" y1="9.55029" x2="74.5217" y2="16.8375" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint14_linear_569_9096" x1="77.9274" y1="9.43115" x2="84.133" y2="13.4899" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint15_linear_569_9096" x1="85.2812" y1="6.95654" x2="87.4965" y2="7.21349" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint16_linear_569_9096" x1="88.4812" y1="9.43506" x2="95.0301" y2="14.1199" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint17_linear_569_9096" x1="95.6431" y1="9.43506" x2="101.747" y2="13.0951" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint18_linear_569_9096" x1="102.197" y1="6.95654" x2="109.973" y2="10.7735" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint19_linear_569_9096" x1="113.084" y1="9.43506" x2="119.633" y2="14.1199" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint20_linear_569_9096" x1="120.618" y1="9.43115" x2="126.823" y2="13.4886" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint21_linear_569_9096" x1="130.849" y1="8.14258" x2="136.28" y2="10.1274" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint22_linear_569_9096" x1="136.172" y1="6.95654" x2="143.453" y2="10.2589" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <linearGradient id="paint23_linear_569_9096" x1="143.097" y1="9.42432" x2="149.41" y2="13.5325" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
            </defs>
        </svg>
    )
}


export const AppStoreWhite = () => {
    return (
        <svg width="164" height="46" viewBox="0 0 164 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M163.5 41.3889C163.5 43.6493 161.624 45.5 159.277 45.5H4.72876C2.38266 45.5 0.5 43.6483 0.5 41.3889V4.6171C0.5 2.35796 2.38361 0.5 4.72876 0.5H159.276C161.623 0.5 163.499 2.35695 163.499 4.6171L163.5 41.3889Z" stroke="white" />
            <path d="M36.716 22.4857C36.6796 18.5694 40.0217 16.664 40.1745 16.5753C38.2818 13.8984 35.3481 13.5326 34.3171 13.5035C31.8532 13.2519 29.4631 14.9337 28.208 14.9337C26.9278 14.9337 24.995 13.5278 22.9118 13.5691C20.2312 13.6092 17.7234 15.1147 16.348 17.4527C13.5095 22.2196 15.6264 29.2249 18.3459 33.0781C19.7063 34.9652 21.2959 37.0722 23.3766 36.9981C25.4121 36.9167 26.1725 35.7392 28.6289 35.7392C31.0628 35.7392 31.7768 36.9981 33.8988 36.9507C36.0834 36.9167 37.4588 35.0551 38.7715 33.151C40.3436 30.988 40.9749 28.8579 41 28.7485C40.9486 28.7315 36.7573 27.1798 36.716 22.4857Z" fill="white" />
            <path d="M32.7075 10.9687C33.8023 9.64055 34.5514 7.83364 34.3434 6C32.7589 6.06805 30.7772 7.06324 29.636 8.36222C28.6264 9.50688 27.7245 11.383 27.9575 13.1474C29.7375 13.2762 31.5651 12.2762 32.7075 10.9687Z" fill="white" />
            <path d="M65 37H62.1701L60.6199 32.3003H55.2318L53.7551 37H51L56.3383 21H59.6355L65 37ZM60.1526 30.3285L58.7508 26.1506C58.6025 25.7238 58.3246 24.7187 57.9146 23.1365H57.8648C57.7016 23.817 57.4386 24.8221 57.0773 26.1506L55.7003 30.3285H60.1526Z" fill="white" />
            <path d="M78 30.6945C78 32.6001 77.4751 34.1063 76.4252 35.2121C75.4848 36.1964 74.3171 36.688 72.9232 36.688C71.4186 36.688 70.3377 36.1578 69.6795 35.0976H69.6319V41H67.094V28.9186C67.094 27.7206 67.0619 26.4911 67 25.23H69.2319L69.3736 27.006H69.4212C70.2675 25.6679 71.5519 25 73.2755 25C74.623 25 75.7479 25.5219 76.6478 26.567C77.55 27.6131 78 28.9886 78 30.6945ZM75.4146 30.7856C75.4146 29.695 75.1646 28.796 74.6623 28.0884C74.1135 27.3504 73.3767 26.9815 72.453 26.9815C71.8269 26.9815 71.2579 27.187 70.7496 27.5921C70.2401 28.0008 69.9068 28.5344 69.7509 29.1953C69.6723 29.5035 69.633 29.7557 69.633 29.9542V31.8224C69.633 32.6375 69.8878 33.3252 70.3973 33.8868C70.9067 34.4484 71.5686 34.7287 72.3827 34.7287C73.3386 34.7287 74.0826 34.3667 74.6147 33.6451C75.1479 32.9224 75.4146 31.9696 75.4146 30.7856Z" fill="white" />
            <path d="M92 30.6945C92 32.6001 91.475 34.1063 90.4238 35.2121C89.4845 36.1964 88.3167 36.688 86.9226 36.688C85.4179 36.688 84.3369 36.1578 83.6798 35.0976H83.6321V41H81.0941V28.9186C81.0941 27.7206 81.0619 26.4911 81 25.23H83.2321L83.3738 27.006H83.4214C84.2667 25.6679 85.5512 25 87.2762 25C88.6226 25 89.7476 25.5219 90.65 26.567C91.5488 27.6131 92 28.9886 92 30.6945ZM89.4143 30.7856C89.4143 29.695 89.1631 28.796 88.6607 28.0884C88.1119 27.3504 87.3774 26.9815 86.4524 26.9815C85.825 26.9815 85.2571 27.187 84.7476 27.5921C84.2381 28.0008 83.906 28.5344 83.75 29.1953C83.6726 29.5035 83.6321 29.7557 83.6321 29.9542V31.8224C83.6321 32.6375 83.8869 33.3252 84.394 33.8868C84.9036 34.4473 85.5655 34.7287 86.3821 34.7287C87.3381 34.7287 88.0821 34.3667 88.6143 33.6451C89.1476 32.9224 89.4143 31.9696 89.4143 30.7856Z" fill="white" />
            <path d="M107 32.4114C107 33.7334 106.524 34.8091 105.568 35.6394C104.518 36.5469 103.056 37 101.178 37C99.4431 37 98.0526 36.6777 97 36.0318L97.5984 33.9565C98.7321 34.6175 99.976 34.9492 101.331 34.9492C102.304 34.9492 103.061 34.7366 103.605 34.3139C104.146 33.8911 104.416 33.3235 104.416 32.6158C104.416 31.9851 104.194 31.4537 103.747 31.0228C103.302 30.5918 102.56 30.1912 101.523 29.821C98.7006 28.8061 97.2907 27.3194 97.2907 25.3644C97.2907 24.0867 97.7849 23.0391 98.7745 22.2239C99.7604 21.4076 101.076 21 102.721 21C104.187 21 105.406 21.2464 106.379 21.7381L105.733 23.7679C104.825 23.2914 103.797 23.0531 102.648 23.0531C101.74 23.0531 101.03 23.2692 100.521 23.699C100.091 24.0832 99.8755 24.5515 99.8755 25.1063C99.8755 25.7206 100.121 26.2286 100.616 26.628C101.046 26.9971 101.827 27.3965 102.961 27.8274C104.347 28.3658 105.366 28.9953 106.021 29.7171C106.674 30.4365 107 31.3369 107 32.4114Z" fill="white" />
            <path d="M116 26.9138H113.185V32.6501C113.185 34.1091 113.681 34.838 114.675 34.838C115.132 34.838 115.51 34.7973 115.81 34.7161L115.88 36.7094C115.377 36.9027 114.715 37 113.894 37C112.886 37 112.098 36.6836 111.529 36.052C110.962 35.4191 110.677 34.3578 110.677 32.8668V26.9114H109V24.9414H110.677V22.7781L113.185 22V24.9414H116V26.9138Z" fill="white" />
            <path d="M130 30.8927C130 32.6611 129.461 34.113 128.386 35.2484C127.259 36.4173 125.763 37 123.897 37C122.1 37 120.668 36.4401 119.601 35.3203C118.534 34.2005 118 32.787 118 31.0833C118 29.3005 118.549 27.8402 119.651 26.7049C120.75 25.5683 122.234 25 124.099 25C125.897 25 127.343 25.5599 128.435 26.6809C129.479 27.7683 130 29.1722 130 30.8927ZM127.176 30.9754C127.176 29.9144 126.935 29.0044 126.446 28.2455C125.875 27.3271 125.059 26.8691 124.002 26.8691C122.908 26.8691 122.077 27.3283 121.506 28.2455C121.017 29.0056 120.776 29.93 120.776 31.0234C120.776 32.0844 121.017 32.9944 121.506 33.7521C122.094 34.6705 122.917 35.1285 123.978 35.1285C125.017 35.1285 125.833 34.6609 126.421 33.7281C126.923 32.9548 127.176 32.0353 127.176 30.9754Z" fill="white" />
            <path d="M137 27.539C136.753 27.4912 136.49 27.4667 136.215 27.4667C135.338 27.4667 134.66 27.8134 134.183 28.5082C133.769 29.1209 133.561 29.8953 133.561 30.8303V37H131.07L131.093 28.9445C131.093 27.5892 131.062 26.3553 131 25.2426H133.17L133.261 27.4924H133.33C133.593 26.7192 134.008 26.0967 134.576 25.6298C135.131 25.2095 135.731 25 136.377 25C136.607 25 136.815 25.0172 137 25.0478V27.539Z" fill="white" />
            <path d="M149 30.4768C149 30.9357 148.971 31.3226 148.909 31.6385H141.409C141.438 32.7774 141.8 33.6483 142.497 34.249C143.129 34.786 143.946 35.0551 144.95 35.0551C146.06 35.0551 147.073 34.8737 147.985 34.5097L148.376 36.2876C147.311 36.7633 146.054 37 144.604 37C142.859 37 141.489 36.4738 140.493 35.4227C139.498 34.3715 139 32.96 139 31.1892C139 29.4509 139.463 28.0033 140.391 26.8488C141.362 25.6163 142.674 25 144.325 25C145.947 25 147.174 25.6163 148.008 26.8488C148.668 27.8279 149 29.0388 149 30.4768ZM146.616 29.8125C146.633 29.0533 146.47 28.3973 146.131 27.8435C145.698 27.1311 145.033 26.7756 144.138 26.7756C143.321 26.7756 142.656 27.1227 142.148 27.8195C141.732 28.3733 141.485 29.0376 141.409 29.8113H146.616V29.8125Z" fill="white" />
            <path d="M60 10.808C60 12.225 59.5402 13.2916 58.6219 14.008C57.7713 14.6689 56.5625 15 54.9968 15C54.2205 15 53.5562 14.9687 53 14.9061V7.16373C53.7255 7.05538 54.5071 7 55.3511 7C56.8426 7 57.9667 7.29977 58.7248 7.89932C59.5741 8.57713 60 9.54627 60 10.808ZM58.5607 10.8429C58.5607 9.9243 58.2975 9.22001 57.7713 8.72882C57.2451 8.23883 56.4766 7.99323 55.4645 7.99323C55.0346 7.99323 54.6686 8.01971 54.3651 8.07509V13.961C54.5331 13.9851 54.8405 13.9959 55.2873 13.9959C56.332 13.9959 57.1383 13.7275 57.7062 13.1905C58.2741 12.6536 58.5607 11.871 58.5607 10.8429Z" fill="white" />
            <path d="M67 11.9457C67 12.8304 66.731 13.5552 66.193 14.1239C65.629 14.7084 64.8817 15 63.9487 15C63.0494 15 62.3333 14.7206 61.7992 14.1592C61.2664 13.5991 61 12.8926 61 12.0409C61 11.1501 61.2742 10.4192 61.8252 9.85174C62.3762 9.28432 63.117 9 64.05 9C64.9493 9 65.6719 9.27944 66.219 9.83954C66.7388 10.3838 67 11.0866 67 11.9457ZM65.5874 11.9872C65.5874 11.4564 65.4652 11.0012 65.2222 10.6217C64.9363 10.1629 64.5296 9.9335 64.0007 9.9335C63.4535 9.9335 63.0377 10.1629 62.7518 10.6217C62.5075 11.0012 62.3866 11.4637 62.3866 12.0104C62.3866 12.5412 62.5088 12.9963 62.7518 13.3758C63.0468 13.8347 63.4574 14.0641 63.9877 14.0641C64.5075 14.0641 64.9155 13.831 65.2092 13.3636C65.4613 12.9768 65.5874 12.518 65.5874 11.9872Z" fill="white" />
            <path d="M76 9L74.2723 15H73.1479L72.4322 12.3946C72.2507 11.7442 72.1031 11.0976 71.9883 10.4561H71.966C71.8594 11.1154 71.7119 11.7607 71.5221 12.3946L70.7619 15H69.6246L68 9H69.2615L69.8858 11.8524C70.0369 12.5269 70.1611 13.1697 70.2606 13.7781H70.2829C70.3742 13.2766 70.5253 12.6377 70.7385 11.8651L71.5221 9.00127H72.5224L73.2732 11.804C73.4548 12.4875 73.6023 13.1455 73.716 13.7794H73.7499C73.8331 13.1621 73.9584 12.504 74.1247 11.804L74.7947 9.00127H76V9Z" fill="white" />
            <path d="M83 15H81.7319V11.6341C81.7319 10.5969 81.3495 10.0783 80.5823 10.0783C80.2059 10.0783 79.9022 10.2204 79.6663 10.5059C79.4327 10.7914 79.3141 11.128 79.3141 11.5132V14.9988H78.046V10.8026C78.046 10.2865 78.0303 9.72678 78 9.12092H79.1145L79.1738 10.0397H79.2089C79.3565 9.75421 79.5767 9.5186 79.8659 9.33036C80.2096 9.11095 80.5944 9 81.0155 9C81.5479 9 81.9908 9.17702 82.3429 9.53231C82.781 9.96738 83 10.6169 83 11.4795V15Z" fill="white" />
            <path d="M86 15H85V7H86V15Z" fill="white" />
            <path d="M94 11.9457C94 12.8304 93.731 13.5552 93.193 14.1239C92.629 14.7084 91.8804 15 90.9487 15C90.0481 15 89.332 14.7206 88.7992 14.1592C88.2664 13.5991 88 12.8926 88 12.0409C88 11.1501 88.2742 10.4192 88.8252 9.85174C89.3762 9.28432 90.117 9 91.0487 9C91.9493 9 92.6706 9.27944 93.219 9.83954C93.7388 10.3838 94 11.0866 94 11.9457ZM92.5861 11.9872C92.5861 11.4564 92.4639 11.0012 92.2209 10.6217C91.9363 10.1629 91.5283 9.9335 91.0006 9.9335C90.4522 9.9335 90.0364 10.1629 89.7518 10.6217C89.5075 11.0012 89.3866 11.4637 89.3866 12.0104C89.3866 12.5412 89.5088 12.9963 89.7518 13.3758C90.0468 13.8347 90.4574 14.0641 90.9877 14.0641C91.5075 14.0641 91.9142 13.831 92.2079 13.3636C92.4613 12.9768 92.5861 12.518 92.5861 11.9872Z" fill="white" />
            <path d="M101 14.8694H99.7842L99.6835 14.2068H99.646C99.23 14.7352 98.6369 15 97.8669 15C97.292 15 96.8269 14.8255 96.4767 14.479C96.1589 14.1641 96 13.7724 96 13.3075C96 12.6046 96.3101 12.0689 96.9341 11.698C97.5568 11.327 98.4328 11.1452 99.5607 11.1538V11.0464C99.5607 10.2886 99.1395 9.91031 98.2959 9.91031C97.6951 9.91031 97.1654 10.0531 96.708 10.3362L96.4328 9.49664C96.9987 9.16595 97.6977 9 98.522 9C100.114 9 100.912 9.79317 100.912 11.3795V13.4979C100.912 14.0726 100.942 14.5302 101 14.8694ZM99.5943 12.8926V12.0055C98.1008 11.9811 97.354 12.3679 97.354 13.1647C97.354 13.4649 97.4393 13.6894 97.6137 13.8395C97.7881 13.9896 98.0103 14.0641 98.2752 14.0641C98.5724 14.0641 98.8501 13.975 99.1034 13.798C99.3579 13.6199 99.5142 13.3941 99.5723 13.1171C99.5866 13.0549 99.5943 12.9793 99.5943 12.8926Z" fill="white" />
            <path d="M108 14.8774H106.76L106.695 14.0103H106.656C106.26 14.6701 105.585 15 104.637 15C103.88 15 103.249 14.7446 102.749 14.2337C102.249 13.7228 102 13.0596 102 12.2451C102 11.3711 102.271 10.6632 102.815 10.1226C103.341 9.61856 103.987 9.36655 104.755 9.36655C105.599 9.36655 106.189 9.61054 106.525 10.0997H106.552V7H107.951V13.4227C107.951 13.9485 107.967 14.433 108 14.8774ZM106.552 12.6002V11.6999C106.552 11.5441 106.539 11.4181 106.513 11.3219C106.435 11.0332 106.265 10.7904 106.008 10.5945C105.748 10.3986 105.435 10.3001 105.073 10.3001C104.552 10.3001 104.144 10.4777 103.844 10.8339C103.547 11.1901 103.396 11.6449 103.396 12.2005C103.396 12.7342 103.539 13.1672 103.825 13.5006C104.128 13.8557 104.536 14.0332 105.047 14.0332C105.505 14.0332 105.872 13.8855 106.151 13.5888C106.42 13.315 106.552 12.9851 106.552 12.6002Z" fill="white" />
            <path d="M119 11.9457C119 12.8304 118.731 13.5552 118.193 14.1239C117.629 14.7084 116.883 15 115.949 15C115.051 15 114.335 14.7206 113.799 14.1592C113.266 13.5991 113 12.8926 113 12.0409C113 11.1501 113.274 10.4192 113.825 9.85174C114.376 9.28432 115.117 9 116.051 9C116.949 9 117.673 9.27944 118.219 9.83954C118.739 10.3838 119 11.0866 119 11.9457ZM117.589 11.9872C117.589 11.4564 117.467 11.0012 117.224 10.6217C116.936 10.1629 116.531 9.9335 116.001 9.9335C115.455 9.9335 115.039 10.1629 114.752 10.6217C114.507 11.0012 114.387 11.4637 114.387 12.0104C114.387 12.5412 114.509 12.9963 114.752 13.3758C115.047 13.8347 115.457 14.0641 115.988 14.0641C116.507 14.0641 116.917 13.831 117.211 13.3636C117.461 12.9768 117.589 12.518 117.589 11.9872Z" fill="white" />
            <path d="M126 15H124.733V11.6341C124.733 10.5969 124.35 10.0783 123.582 10.0783C123.205 10.0783 122.901 10.2204 122.667 10.5059C122.432 10.7914 122.314 11.128 122.314 11.5132V14.9988H121.045V10.8026C121.045 10.2865 121.03 9.72678 121 9.12092H122.114L122.173 10.0397H122.208C122.357 9.75421 122.577 9.5186 122.865 9.33036C123.21 9.11095 123.594 9 124.016 9C124.548 9 124.991 9.17702 125.343 9.53231C125.782 9.96738 126 10.6169 126 11.4795V15Z" fill="white" />
            <path d="M135 10.2912H133.392V12.9696C133.392 13.6503 133.678 13.9906 134.243 13.9906C134.505 13.9906 134.723 13.9719 134.894 13.9333L134.932 14.8632C134.643 14.9544 134.264 15 133.798 15C133.221 15 132.772 14.8526 132.448 14.5579C132.122 14.2632 131.96 13.7673 131.96 13.0713V10.2912H131V9.3731H131.96V8.36257L133.391 8V9.37193H134.999V10.2912H135Z" fill="white" />
            <path d="M141 15H139.719V11.8824C139.719 10.8994 139.333 10.4073 138.56 10.4073C137.966 10.4073 137.561 10.6923 137.339 11.2623C137.301 11.3821 137.279 11.5287 137.279 11.7009V14.9988H136V7H137.279V10.3049H137.303C137.706 9.7035 138.284 9.40337 139.032 9.40337C139.563 9.40337 140.001 9.56856 140.349 9.9001C140.783 10.3131 141 10.9273 141 11.7393V15Z" fill="white" />
            <path d="M148 11.7377C148 11.9676 147.983 12.1608 147.953 12.3185H144.204C144.22 12.8883 144.399 13.3224 144.747 13.6232C145.064 13.8922 145.473 14.0267 145.974 14.0267C146.529 14.0267 147.035 13.9362 147.491 13.754L147.686 14.6442C147.153 14.8814 146.525 15 145.799 15C144.928 15 144.242 14.7371 143.746 14.2113C143.247 13.6856 143 12.98 143 12.0948C143 11.2254 143.23 10.5015 143.694 9.92439C144.179 9.30813 144.834 9 145.66 9C146.469 9 147.084 9.30813 147.499 9.92439C147.834 10.4135 148 11.0187 148 11.7377ZM146.807 11.4064C146.817 11.0261 146.734 10.6984 146.565 10.4221C146.348 10.0662 146.017 9.88771 145.57 9.88771C145.162 9.88771 144.829 10.0613 144.575 10.4098C144.367 10.6874 144.244 11.0187 144.204 11.4064H146.807Z" fill="white" />
        </svg>
    )
}