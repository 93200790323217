export const Cookies = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
            <g clip-path="url(#clip0_569_9888)">
                <path d="M2.81687 0.5H37.1831C38.4627 0.5 39.5 1.53731 39.5 2.81687V7H20H0.5V2.81687C0.5 1.53731 1.53731 0.5 2.81687 0.5Z" fill="white" stroke="#E8D9FD" />
                <path d="M37 3.75C37 4.16421 36.6642 4.5 36.25 4.5C35.8358 4.5 35.5 4.16421 35.5 3.75C35.5 3.33579 35.8358 3 36.25 3C36.6642 3 37 3.33579 37 3.75Z" stroke="#E8D9FD" />
                <path d="M32 3.75C32 4.16421 31.6642 4.5 31.25 4.5C30.8358 4.5 30.5 4.16421 30.5 3.75C30.5 3.33579 30.8358 3 31.25 3C31.6642 3 32 3.33579 32 3.75Z" stroke="#E8D9FD" />
                <path d="M27 3.75C27 4.16421 26.6642 4.5 26.25 4.5C25.8358 4.5 25.5 4.16421 25.5 3.75C25.5 3.33579 25.8358 3 26.25 3C26.6642 3 27 3.33579 27 3.75Z" stroke="#E8D9FD" />
                <path d="M11.9999 4.39633V4.39645C11.9999 4.45367 11.9536 4.5 11.8964 4.5H3.10359C3.04638 4.5 3 4.45362 3 4.39641V3.10359C3 3.04638 3.04638 3 3.10359 3H11.8963C11.9535 3 11.9999 3.04638 11.9999 3.10359V4.39633Z" stroke="#E8D9FD" />
                <path d="M29.9194 15.4962L0.5 14.2704V6.66101H39.5V11.9035L29.9194 15.4962Z" fill="white" stroke="#E8D9FD" />
                <path d="M30.6482 12.5589L32.5589 10.6482C32.6538 10.5533 32.7825 10.5 32.9167 10.5H39.5V32.1831C39.5 33.4627 38.4627 34.5 37.183 34.5H2.81687C1.5373 34.5 0.5 33.4627 0.5 32.1832V13.0001H29.5832C29.9827 13.0001 30.3657 12.8414 30.6482 12.5589Z" fill="white" stroke="#E8D9FD" />
                <path d="M28.8387 27.5001C28.0213 26.6826 27.6602 25.5816 27.7553 24.5136C27.7628 24.4308 27.7206 24.3525 27.6471 24.3134C26.4725 23.6883 25.7046 22.5575 25.5036 21.3084C25.4904 21.2262 25.43 21.1611 25.3492 21.1411C23.7132 20.7378 22.4999 19.2606 22.4999 17.5001C22.4999 17.2379 22.5269 16.9822 22.5781 16.7353C22.6005 16.6271 22.5321 16.5201 22.4239 16.4976C21.6234 16.3316 20.7935 16.2461 19.9428 16.2501C13.3724 16.2811 8.04268 21.7087 8.12596 28.2786C8.20815 34.7663 13.4928 40.0001 19.9999 40.0001C26.3299 40.0001 31.5032 35.0472 31.8558 28.8054C31.8625 28.687 31.7638 28.5903 31.6453 28.5951C30.6346 28.6367 29.6103 28.2717 28.8387 27.5001Z" fill="url(#paint0_linear_569_9888)" />
                <g style={{mixBlendMode:"soft-light"}}>
                    <path d="M31.6452 28.5951C30.6346 28.6366 29.6103 28.2716 28.8387 27.5C28.766 27.4274 28.697 27.3525 28.6317 27.2756C28.2396 33.4798 23.0836 38.3904 16.7806 38.3904C15.5131 38.3904 14.292 38.1918 13.1467 37.8241C15.0828 39.1945 17.4473 40 19.9999 40C26.3299 40 31.5031 35.0472 31.8558 28.8054C31.8624 28.687 31.7638 28.5902 31.6452 28.5951Z" fill="#272727" />
                </g>
                <path d="M21.6952 26.25H19.5548C19.1103 26.25 18.75 25.8897 18.75 25.4452V23.3048C18.75 22.8603 19.1103 22.5 19.5548 22.5H21.6952C22.1397 22.5 22.5 22.8603 22.5 23.3048V25.4452C22.5 25.8897 22.1397 26.25 21.6952 26.25Z" fill="white" />
                <path d="M18.3475 34.6055L16.4939 35.6757C16.109 35.8979 15.6167 35.7661 15.3944 35.3811L14.3243 33.5275C14.102 33.1425 14.2339 32.6503 14.6189 32.428L16.4725 31.3579C16.8574 31.1356 17.3497 31.2675 17.5719 31.6525L18.6421 33.5061C18.8644 33.8911 18.7325 34.3832 18.3475 34.6055Z" fill="white" />
                <path d="M26.0756 30.1501L25.1502 31.0755C24.9176 31.3081 24.5405 31.3081 24.3079 31.0755L23.3824 30.1501C23.1499 29.9175 23.1499 29.5404 23.3824 29.3078L24.3079 28.3823C24.5405 28.1497 24.9176 28.1497 25.1502 28.3823L26.0756 29.3078C26.3082 29.5404 26.3082 29.9175 26.0756 30.1501Z" fill="white" />
                <path d="M16.0756 24.4425L15.1502 25.368C14.9176 25.6006 14.5405 25.6006 14.3079 25.368L13.3824 24.4425C13.1499 24.21 13.1499 23.8328 13.3824 23.6003L14.3079 22.6748C14.5405 22.4422 14.9176 22.4422 15.1502 22.6748L16.0756 23.6003C16.3082 23.8328 16.3082 24.21 16.0756 24.4425Z" fill="white" />
                <path d="M13.75 30.5859C14.0736 30.5859 14.3359 30.3236 14.3359 30C14.3359 29.6764 14.0736 29.4141 13.75 29.4141C13.4264 29.4141 13.1641 29.6764 13.1641 30C13.1641 30.3236 13.4264 30.5859 13.75 30.5859Z" fill="white" />
                <path d="M11.25 28.0859C11.5736 28.0859 11.8359 27.8236 11.8359 27.5C11.8359 27.1764 11.5736 26.9141 11.25 26.9141C10.9264 26.9141 10.6641 27.1764 10.6641 27.5C10.6641 27.8236 10.9264 28.0859 11.25 28.0859Z" fill="white" />
                <path d="M18.75 29.3359C19.0736 29.3359 19.3359 29.0736 19.3359 28.75C19.3359 28.4264 19.0736 28.1641 18.75 28.1641C18.4264 28.1641 18.1641 28.4264 18.1641 28.75C18.1641 29.0736 18.4264 29.3359 18.75 29.3359Z" fill="white" />
                <path d="M21.25 33.0859C21.5736 33.0859 21.8359 32.8236 21.8359 32.5C21.8359 32.1764 21.5736 31.9141 21.25 31.9141C20.9264 31.9141 20.6641 32.1764 20.6641 32.5C20.6641 32.8236 20.9264 33.0859 21.25 33.0859Z" fill="white" />
                <path d="M23.75 35.5859C24.0736 35.5859 24.3359 35.3236 24.3359 35C24.3359 34.6764 24.0736 34.4141 23.75 34.4141C23.4264 34.4141 23.1641 34.6764 23.1641 35C23.1641 35.3236 23.4264 35.5859 23.75 35.5859Z" fill="white" />
                <path d="M28.75 10.0859H7.5C7.45255 10.0859 7.41406 10.0475 7.41406 10C7.41406 9.95255 7.45255 9.91406 7.5 9.91406H28.75C28.7975 9.91406 28.8359 9.95255 28.8359 10C28.8359 10.0475 28.7975 10.0859 28.75 10.0859Z" fill="white" stroke="#E8D9FD" />
                <path d="M5.08594 10C5.08594 10.0475 5.04746 10.0859 5 10.0859C4.95254 10.0859 4.91406 10.0475 4.91406 10C4.91406 9.95254 4.95254 9.91406 5 9.91406C5.04746 9.91406 5.08594 9.95254 5.08594 10Z" fill="white" stroke="#E8D9FD" />
                <path d="M2.58594 10C2.58594 10.0475 2.54746 10.0859 2.5 10.0859C2.45254 10.0859 2.41406 10.0475 2.41406 10C2.41406 9.95254 2.45254 9.91406 2.5 9.91406C2.54746 9.91406 2.58594 9.95254 2.58594 10Z" fill="white" stroke="#E8D9FD" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_569_9888" x1="8.125" y1="16.25" x2="34.7986" y2="36.0326" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0014FF" />
                    <stop offset="1" stop-color="#FF2CDF" />
                </linearGradient>
                <clipPath id="clip0_569_9888">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
